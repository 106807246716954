import { Controller } from "@hotwired/stimulus"
import { tempusDominus, DATE_DEFAULT_OPTIONS } from "../libs/tempus-dominus"

export default class extends Controller {
  static targets = ["dateFrom", "dateTo"]

  static values = {
    format: { type: String, default: "dd.MM.yyyy" },
    locale: { type: String, default: "pl" },
    withTime: { type: Boolean, default: false }
  }

  connect() {
    this.dateFrom = new tempusDominus.TempusDominus(this.dateFromTarget, this.dateFromOptions)
    this.dateTo = new tempusDominus.TempusDominus(this.dateToTarget, this.dateToOptions)
    this.dateFromObserveOnChange()
    this.dateToObserveOnChange()
  }

  get dateFromOptions() {
    return Object.assign({ restrictions: { maxDate: this.dateToValue } }, this.defaultDateOptions)
  }

  get dateToOptions() {
    return Object.assign({ restrictions: { minDate: this.dateFromValue } }, this.defaultDateOptions)
  }

  get dateFromValue() {
    return (this.dateFromTarget.value.trim().length === 0) ? undefined : this.dateFromTarget.value
  }

  get dateToValue() {
    return (this.dateToTarget.value.trim().length === 0) ? undefined : this.dateToTarget.value
  }

  dateFromObserveOnChange() {
    this.dateFromTarget.addEventListener(tempusDominus.Namespace.events.change, (event) => {
      this.dateTo.updateOptions({
        restrictions: {
          minDate: event.detail.date,
        }
      })
    })
  }

  dateToObserveOnChange() {
    this.dateToTarget.addEventListener(tempusDominus.Namespace.events.change, (event) => {
      this.dateFrom.updateOptions({
        restrictions: {
          maxDate: event.detail.date,
        }
      })
    })
  }

  get defaultDateOptions() {
    if (this.withTimeValue) {
      DATE_DEFAULT_OPTIONS.display.components.hours = true
      DATE_DEFAULT_OPTIONS.display.components.minutes = true
    }
    else {
      DATE_DEFAULT_OPTIONS.display.components.hours = false
      DATE_DEFAULT_OPTIONS.display.components.minutes = false
    }

    return Object.assign(
      {
        localization: {
          locale: this.localeValue,
          format: this.formatValue,
          startOfTheWeek: 1
        }
      }, DATE_DEFAULT_OPTIONS
    )
  }
}
