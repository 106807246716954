// cookiesboot loads automaticly on domready but not when visiting next pages
// by turbo. Also Load event is fired later that visit thats why we only
// display consest cookie on visit.
document.addEventListener("turbo:visit", function(event) {
  window.isTurboVisit = true
})
document.addEventListener("turbo:load", function(event) {
  if (window.isTurboVisit && !CookieConsent.hasResponse) {
    CookieConsent.show()
  }
  window.isTurboVisit = false
})
