import { Controller } from "@hotwired/stimulus"
import * as PDFObject from "pdfobject"

// Connects to data-controller="pdf-object"
export default class extends Controller {
  static targets = [ "container" ]
  static values = {
    source: String,
    fallbackLink: String
  }
  static classes = [ "pdf" ]

  connect() {
    this.embedPdfObject()
    this.addClassesToPdfObject()
  }

  embedPdfObject() {
    return PDFObject.embed(this.source, this.container, this.options)
  }

  get options() {
    return Object.assign(
      {
        omitInlineStyles: true,
        fallbackLink: this.fallbackLink
      }
    )
  }

  get container() {
    return this.containerTarget
  }

  get source() {
    return this.sourceValue
  }

  get fallbackLink() {
    return this.fallbackLinkValue ||
      `
      <p>
        Twoja przeglądarka nie wspiera wyświetlania plików PDF.
        Pobierz <a class="link-primary" href='[url]'>plik</a> aby wyświetlić.
      </p>
      `
  }

  addClassesToPdfObject() {
    if (this.hasPdfClass) {
      this.embedPdfObject().classList.add(...this.pdfClasses)
    }
  }
}
