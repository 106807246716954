import { Controller } from "@hotwired/stimulus"
import leaflet from "leaflet"

export default class extends Controller {
  static values = {
    iconUrl: String,
    lat: Number,
    lon: Number,
    zoom: { type: Number, default: 1 }
  }

  connect() {
    this.createMap()
    this.disablePrefixAttributionControl()
    this.addMarker()
    this.addTileLayer()
  }

  createMap() {
    this.map = leaflet.map(this.element, {
      zoomControl: false,
      scrollWheelZoom: false
    }).setView([this.latValue, this.lonValue], this.zoomValue);
  }

  disablePrefixAttributionControl() {
    this.map.attributionControl.setPrefix(false)
  }

  addMarker() {
    leaflet.marker([this.latValue, this.lonValue], { icon: this.markerIcon }).addTo(this.map);
  }

  get markerIcon() {
    return leaflet.icon({
      iconUrl: this.iconUrlValue,
      iconSize: [36, 54],
      iconAnchor: [18, 54]
    });
  }

  addTileLayer() {
    leaflet.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
    }).addTo(this.map)
  }
}
