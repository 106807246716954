import * as tempusDominus from "@eonasdan/tempus-dominus"

const DATE_DEFAULT_OPTIONS = {
  display: {
    components: {
      decades: true,
      year: true,
      month: true,
      date: true,
      hours: false,
      minutes: false,
      seconds: false
    },
    icons: {
      time: "bi bi-clock",
      date: "bi bi-calendar",
      up: "bi bi-arrow-up",
      down: "bi bi-arrow-down",
      previous: "bi bi-chevron-left",
      next: "bi bi-chevron-right",
      today: "bi bi-calendar-check",
      clear: "bi bi-trash",
      close: "bi bi-x"
    },
    buttons: {
      today: true,
      clear: true,
      close: true
    }
  }
}

export { tempusDominus, DATE_DEFAULT_OPTIONS }
